.head {
    padding: 15px 21px;
    background: #e31673;
    color: #fff;
    font-size: 15px;
}
.idCardInfo {
    margin: 22px auto;
    text-align: center;
    line-height: 1.5rem;
}

.divider {
    height: 1px;
    background: #ededed;
}

.footer {
    padding: 28px;
    color: #666;
    word-break: break-all;
    font-size: 14px;
}

.cardWarp {
    margin-top: 12px;
    padding: 1px;
    background: #fff;
}
.cardTitle {
    display: flex;
    align-items: center;
    padding: 14px;
    border-bottom: 1px solid#ededed;
    line-height: 1rem;
}
.vertical {
    display: inline-block;
    margin-right: 8px;
    width: 3px;
    height: 15px;
    border-radius: 10px;
    background: #f02274;
    color: #333333;
    font-weight: 500;
}

.item {
    display: flex;
    margin: 14px;
}
.label {
    margin-right: 24px;
    color: #666;
}

.backToHomeWrap {
    position: absolute;
    top: 80%;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 6px 6px 12px 0 rgba(0, 0, 0, 0.12);
}

.loadingWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
